import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'

import { EMISSION_EVENT_ATTRIBUTION_TYPES } from 'app/MissionControlMethaneSolution/constants/emissionEvent'

export const getAttributionName = ({
  sourceAttribution,
  site,
  equipmentDescription,
}: Pick<DetectionData, 'sourceAttribution' | 'site'> & {
  equipmentDescription?: string | null
}): string | null | undefined => {
  if (!sourceAttribution) return ''

  const isAttributedToEquipment =
    sourceAttribution.attributionType ===
    EMISSION_EVENT_ATTRIBUTION_TYPES.EQUIPMENT

  return isAttributedToEquipment ? equipmentDescription : site.name
}
