// libraries
import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { DATE_FORMAT, UTC } from 'constants/datetime'
import { NO_VALUE_PLACEHOLDER } from 'app/MissionControlMethaneSolution/constants/common'

// utils
import { useTimezone } from 'hooks'
import { displayTime } from 'helpers/utils'

// components
import { Tooltip } from 'components/common'

import type { Timezone } from 'types/datetime'

interface DateTimeTemplateProps {
  rowData: unknown
  options: ColumnBodyOptions
  timeFormat?: string
  defaultTimezone?: Timezone
}

const DateTimeFormattedTemplate = ({
  rowData,
  options,
  timeFormat,
  defaultTimezone,
}: DateTimeTemplateProps): ReactElement | string => {
  const { timezone } = useTimezone()
  const datetime = _.get(rowData, options.field)

  if (!datetime) {
    return NO_VALUE_PLACEHOLDER
  }

  const tooltipTimezone = defaultTimezone === UTC ? timezone : UTC

  const tooltipContent = displayTime({
    datetime,
    timeFormat,
    timezone: tooltipTimezone,
  })

  const displayTimezone = defaultTimezone ?? timezone

  const formattedTime = displayTime({
    datetime,
    timeFormat,
    timezone: displayTimezone,
  })

  return tooltipTimezone === formattedTime ? (
    formattedTime
  ) : (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{tooltipContent}</span>}
    >
      <span>{formattedTime}</span>
    </Tooltip>
  )
}

export const DateTimeTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <DateTimeFormattedTemplate rowData={rowData} options={options} />
}

export const DateTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return (
    <DateTimeFormattedTemplate
      rowData={rowData}
      options={options}
      timeFormat={DATE_FORMAT}
    />
  )
}

export default DateTimeFormattedTemplate
