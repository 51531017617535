import { useMemo } from 'react'
import { DETECTION_TYPES_TO_OMIT_ON_MAP } from 'app/MissionControlMethaneSolution/constants/emissionEvent'
import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'
import type { GeojsonData } from 'types/map'

const useDetectionsGeoJsonFeatures = (detections?: DetectionData[]) =>
  useMemo(() => {
    const collectedFeatures = detections?.reduce<GeojsonData[]>(
      (acc, { geoJsonFeature, detectionType }) => {
        if (
          !geoJsonFeature ||
          // For now, omit certain detection types since they usually have incorrect coordinates
          DETECTION_TYPES_TO_OMIT_ON_MAP.includes(detectionType)
        )
          return acc

        return [...acc, geoJsonFeature]
      },
      []
    )

    return collectedFeatures || []
  }, [detections])

export default useDetectionsGeoJsonFeatures
