import keymirror from 'keymirror'

import type { DetectionType } from 'app/MissionControlMethaneSolution/types/detection'
import { DETECTION_TYPES } from './common'

export const VFB_EVENT_TYPE = 'VFB'

export const EMISSION_EVENT_TYPE_ICONS_MAPPING = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'MethaneCMSIcon',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'MethaneDroneIcon',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'MethaneFlyOverIcon',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'MethaneSatelliteIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'MethaneOGIIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'FaWalking',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'MethaneManualIcon',
  [VFB_EVENT_TYPE]: 'MethaneVFBIcon',
}

export const EMISSION_EVENT_TYPE_TOOLTIPS = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'Continuous Monitoring System',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'Drone',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'Optical Gas Imaging',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'On-site Walk Down',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'Flyover',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'Satellite',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'Manual',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'Unknown',
  [VFB_EVENT_TYPE]: 'VFB',
}

export const EMISSION_EVENT_ATTRIBUTION_TYPES = keymirror({
  SITE: null,
  EQUIPMENT: null,
})

export const DETECTION_TYPES_TO_OMIT_ON_MAP: DetectionType[] = [
  DETECTION_TYPES.DETECTION_TYPE_CMS,
  DETECTION_TYPES.DETECTION_TYPE_OGI,
]
